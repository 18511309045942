import React from "react";
import {useAsyncDebounce} from "react-table";

const GlobalFilter = ({
                        preGlobalFilteredRows,
                        globalFilter,
                        setGlobalFilter,
                        totalCount
                      }) => {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <span>
      Globaal zoeken:{' '}
      <input
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        style={{
          fontSize: '1.1rem',
          border: '0',
        }}
      />
    </span>
  )
}

export default GlobalFilter;