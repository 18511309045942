// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
import React, {useState} from "react";
import {useAsyncDebounce} from "react-table";
import {Form} from "react-bootstrap";

const NumberRangeColumnFilter = ({
                                   column: { filterValue = [], preFilteredRows, setFilter, id },
                                 }) => {

  const [minValue, setMinValue] = useState(filterValue[0]);
  const [maxValue, setMaxValue] = useState(filterValue[1]);

  const onChangeMin = useAsyncDebounce((value) => {
    setFilter((old = []) => [value ? parseInt(value, 10) : undefined, old[1]])
  }, 500);

  const onChangeMax = useAsyncDebounce((value) => {
    setFilter((old = []) => [old[0], value ? parseInt(value, 10) : undefined])
  }, 500);

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <Form.Control
        value={minValue}
        type="number"
        onChange={(e) => {
          setMinValue(e.target.value);
          onChangeMin(e.target.value)
        }}
        placeholder={`Min`}
        style={{
          width: '70px',
          marginRight: '0.5rem',
        }}
      />
      tot
      <Form.Control
        value={maxValue}
        type="number"
        onChange={(e) => {
          setMaxValue(e.target.value);
          onChangeMax(e.target.value)
        }}
        placeholder={`Max`}
        style={{
          width: '70px',
          marginLeft: '0.5rem',
        }}
      />
    </div>
  )
}

export default NumberRangeColumnFilter;