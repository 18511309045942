import axios from 'axios';
import AuthenticationService from "./authenticationService";
const qs = require('qs');

export default class ApiService {
  static ROOT_URL = process.env.REACT_APP_API_URL;

  static async performRequest(requestType, url, data = {}) {
    const token = AuthenticationService.getToken();
    let headers = {
      'Authorization' : `Bearer ${token}`,
      'Content-Language' : `${localStorage.getItem('i18nextLng')}`
    };
    let requestUrl = this.ROOT_URL + url;
    let response;

    try{
      switch(requestType) {
        case 'GET':
          response = await this.getRequest(requestUrl, headers, data);
          break;
        case 'FILE':
          response = await this.fileRequest(requestUrl, headers);
          const fileName = response.headers['content-disposition'].split('filename=')[1];
          return {
            blob: response.data,
            fileName: fileName.replace('"','').replace('"','')
          };
        case 'PUT':
          response = await this.putRequest(requestUrl, headers, data);
          break;
        case 'DELETE':
          response = await this.deleteRequest(requestUrl, headers);
          break;
        case 'POST':
          response = await this.postRequest(requestUrl, headers, data);
          break;
        case 'PATCH':
          response  = await this.patchRequest(requestUrl, headers, data);
          break;
        default:
          return null;
      }

      return response.data;
    } catch(e) {
      // Logout in case of 401 or 403
      const isLoginAction = e.request.responseURL.includes('/api/login');
      if(e.response && !isLoginAction && (e.response.status === 401 || e.response.status === 403)) {
        AuthenticationService.logout();
      }
      throw e;
    }
  }

  static getRequest = async (url, headers, data) => {
    let queryString = '';
    if (Object.keys(data).length) {
      queryString = '?' + qs.stringify(data);
    }
    return await axios.get(url + queryString, {headers: {...headers}});
  };

  static postRequest = async (url, headers, data) => {
    return await axios.post(url, data, {
      headers: {
        ...headers,
        'Content-Type': 'application/json'
      }
    });
  };

  static patchRequest = async (url, headers, data) => {
    return await axios.patch(url, data, {headers: {...headers}})
  };

  static putRequest = async (url, headers, data) => {
    return await axios.put(url, data, {headers: {...headers}});
  };

  static deleteRequest = async (url, headers) => {
    return await axios.delete(url, {headers: {...headers}});
  };

  static fileRequest = async (url, headers) => {
    return await axios.get(url, { responseType:"blob",  headers: {...headers}});
  };
}
