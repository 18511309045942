import JWT, {JwtPayload} from 'jwt-decode';
import { PublicClientApplication } from "@azure/msal-browser"
import moment from 'moment'

export default class AuthenticationService {
  static CLIENTID = process.env.REACT_APP_AZURE_CLIENTID;
  static AUTHORITY = process.env.REACT_APP_AZURE_AUTHORITY;
  static REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL;

  static async login() {
    const msalConfig = {
      auth: {
        authority: this.AUTHORITY || '',
        clientId: this.CLIENTID || '',
        redirectUri: this.REDIRECT_URL || '',
      }
    }
    const loginConfig = {
      scopes: ['user.read'],
      prompt: "select_account",
      redirectStartPage: this.REDIRECT_URL || ''
    }

    const msalInstance = new PublicClientApplication(msalConfig);

    let token = await msalInstance.acquireTokenPopup(loginConfig);

    try {
      if (token) {
        return token;
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  }

  static isLoggedIn() {
    const loggedIn = localStorage.getItem('loggedIn');
    return loggedIn === '1' && this.isTokenValid();
  };

  static getToken() {
    return localStorage.getItem('token') || '';
  }

  static setToken(token) {
    localStorage.setItem('loggedIn', '1');
    localStorage.setItem('token', token)
  }

  static logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('loggedIn');
    window.location.reload();
  }

  static isAdmin() {
    const roles = this.getRoles();

    return roles.includes('ROLE_ADMIN');
  }

  static getRoles() {
    try {
      const token = JWT(this.getToken());
      return token.roles;
    } catch (e) {
      return [];
    }
  }

  static isTokenValid() {
    if (this.getToken() !== null) {
      try {
        const token = JWT(this.getToken());
        return token.exp && token.exp >= moment().unix();
      } catch (error) {
        return false;
      }
    }

    return false;
  }
}