import React, {useState} from 'react';
import {Alert, Button, Container, Spinner} from "react-bootstrap"
import authenticationService from "./Services/authenticationService";
import axios from "axios";
import {StateContext} from "./Contexts/StateContext";


const Login = () => {

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [state, dispatch] = React.useContext(StateContext);

  const handleLogin = async () => {
    let token = null;
    try {
      token = await authenticationService.login();
    } catch (e) {
      console.log(e.message);
    }

    if (!token) {
      setErrorMessage('Er ging iets fout met inloggen. Probeer het opnieuw');
      return;
    }

    setLoading(true);

    const baseUrl = process.env.REACT_APP_API_URL;
    let error = false;
    axios.post(baseUrl + 'login_check_m365', {token: token.accessToken}).then(response => {
      authenticationService.setToken(response.data.token);
      dispatch({type: 'isLoggedIn', isLoggedIn: true});
      setLoading(false);
    }).catch(error => {
      console.log(error.response.status);
      setErrorMessage(error.response.data.message);
      dispatch({type: 'isLoggedIn', isLoggedIn: false})
      setLoading(false);
    });
  };

  return (
    <Container>
      <div className='mt-5' style={{width: 500, margin: '0 auto'}}>

        <Button
          variant="primary"
          className={'text-uppercase w-100'}
          size={'lg'}
          onClick={handleLogin}
        >
          Login

          {loading &&
            <Spinner size='sm' animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          }
        </Button>

        {!!errorMessage && <Alert variant='danger' className='mt-2'>{errorMessage}</Alert>}
      </div>
    </Container>
  )
}

export default Login