import React, {useMemo, useState, useEffect, useRef, useCallback, Fragment} from 'react';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  useTable,
  matchSorter,
  useGlobalFilter,
  useFilters,
  usePagination,
  useSortBy,
  useExpanded
} from 'react-table'
import {Button, Spinner, Table, Form, Modal} from "react-bootstrap";
import TextAreaCell from "./Editable/TextAreaCell";
import CheckboxCell from "./Editable/CheckboxCell";
import GlobalFilter from "./Filter/GlobalFilter";
import NumberRangeColumnFilter from "./Filter/NumberRangeColumnFilter";
import SelectColumnFilter from "./Filter/SelectColumnFilter";
import DefaultColumnFilter from "./Filter/DefaultColumnFilter";
import FileDownload from "js-file-download";
import SelectCell from "./Editable/SelectCell";
import DateRangeColumnFilter from "./Filter/DateRangeColumnFilter";
import {Formik} from "formik";
import authenticationService from "./Services/authenticationService";
import apiService from "./Services/apiService";

function OrderOverview() {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [controlledPageCount, setControlledPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const fetchIdRef = useRef(0);

  const [showFilters, setShowFilters] = useState(false);
  const [showFilterForm, setShowFilterForm] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState({name: ''});
  const [customFilters, setCustomFilters] = useState([]);
  const [user, setUser] = useState({});

  const [options, setOptions] = useState({
    dealerAccount: [],
    dealerAccountTranslation: [],
    unitCode: [],
    shippingFrom: [],
    status: [],
    shippingMode: [],
    shipTo: [],
    typeOrder: [],
    salesProgram: [],
    salesProgramTranslation: [],
    krSalesProgram: [],
    krSalesStatus: [],
    description: []
  });

  useEffect(() => {
    getOptions();
    getCustomerFilters();
    getUserInfo();
  }, []);

  const getUserInfo = async () => {
    const response = await apiService.performRequest('GET', 'user/info');
    setUser(response);
  }

  const getOptions = async () => {
    const response = await apiService.performRequest('GET', 'order/filter_options');
    setOptions(response);
  }

  const getCustomerFilters = async () => {
    const response = await apiService.performRequest('GET', 'filter');
    setCustomFilters(response);
  }

  const fetchData = useCallback(({pageSize, pageIndex, filters, globalFilter, sortBy}) => {
    setLoading(true);
    const fetchId = ++fetchIdRef.current
    let url = 'order?page={page}&sizePerPage={pageSize}';
    url = url
      .replace('{page}', pageIndex + 1)
      .replace('{pageSize}', pageSize);
    ;

    if (fetchId === fetchIdRef.current) {
      getData(url, {filters, sortBy, globalFilter});
    }
  }, []);

  const getData = async (url, data) => {
    const response = await apiService.performRequest('POST', url, data);
    setData(response.data);
    setControlledPageCount(Math.ceil(response.total / pageSize));
    setTotalCount(response.total);
    setLoading(false);
  }

  const columns = useMemo(
    () => {
      let columns = [
        {
          Header: 'KMP',
          className: 'yellow',
          Filter: false,
          accessor: 'button',
          Cell: props => {
            const data = props.cell.row.original;
            return (
              !data.occasionId ? <Button onClick={() => addToKmp(data)}>KMP</Button> : null
            )
          },
        },
        {
          // Make an expander cell
          Header: () => null, // No header
          id: 'expander', // It needs an ID
          Cell: ({row}) => (
            // Use Cell to render an expander for each row.
            // We can use the getToggleRowExpandedProps prop-getter
            // to build the expander.
            <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? <span className={'expand-icon'}>-</span> : <span className={'expand-icon'}>+</span>}
          </span>
          )
        },
        {
          Header: 'Id',
          accessor: 'id',
          className: 'light-grey',
          minWidth: 150
        },
        {
          Header: 'Description',
          accessor: 'description',
          className: 'light-green',
          Filter: (column) => SelectColumnFilter(column, options.description),
          filter: 'includes',
        },
        {
          Header: 'Customer Order Number',
          accessor: 'customerOrderNumber',
          className: 'light-green'
        },
        {
          Header: 'Machinecode',
          accessor: 'machineCode',
          className: 'yellow'
        },
        {
          Header: 'Web-ID',
          accessor: 'occasionId',
          className: 'yellow',
          Cell: props => <span>{props.value ? props.value : ''}</span>,
        },
        {
          Header: 'Organization',
          accessor: 'account.organization.name',
          className: 'light-green'
        },
        {
          Header: 'Dealer Account',
          accessor: 'dealerAccount',
          className: 'light-green',
          Filter: (column) => SelectColumnFilter(column, options.dealerAccount),
          filter: 'includes',
        },
        {
          Header: 'Dealer Account vertaling',
          accessor: 'dealerAccountTranslation',
          className: 'dark-green',
          Filter: (column) => SelectColumnFilter(column, options.dealerAccountTranslation),
          filter: 'includes',
        },
        {
          Header: 'Unit code',
          accessor: 'unitCode',
          className: 'light-green',
          Filter: (column) => SelectColumnFilter(column, options.unitCode),
          filter: 'includes',
        },
        {
          Header: 'Order number',
          accessor: 'orderNumber',
          className: 'light-green'
        },
        {
          Header: 'Shipping from',
          accessor: 'shippingFrom',
          className: 'light-green',
          Filter: (column) => SelectColumnFilter(column, options.shippingFrom),
          filter: 'includes',
        },
        {
          Header: 'Factory Delivery',
          accessor: 'factoryDelivery',
          Cell: props => <span>{formatDate(props.value)}</span>,
          className: 'light-green',
          Filter: DateRangeColumnFilter,
          filter: 'dateBetween',
        },
        {
          Header: 'Status',
          accessor: 'status',
          className: 'light-green',
          Filter: (column) => SelectColumnFilter(column, options.status),
          filter: 'includes',
        },
        {
          Header: 'Status date',
          accessor: 'statusDate',
          Cell: props => <span>{formatDate(props.value)}</span>,
          className: 'light-green',
          Filter: DateRangeColumnFilter,
          filter: 'dateBetween',
        },
        {
          Header: 'Shipping mode',
          accessor: 'shippingMode',
          className: 'light-green',
          Filter: (column) => SelectColumnFilter(column, options.shippingMode),
          filter: 'includes',
        },
        {
          Header: 'Ship to',
          accessor: 'shipTo',
          className: 'light-green',
          Filter: (column) => SelectColumnFilter(column, options.shipTo),
          filter: 'includes',
        },
        {
          Header: 'Type order',
          accessor: 'typeOrder',
          className: 'light-green',
          Filter: (column) => SelectColumnFilter(column, options.typeOrder),
          filter: 'includes',
        },
        {
          Header: '1 Type order',
          accessor: 'krTypeOrder',
          className: 'dark-green',
          Cell: CheckboxCell,
          Filter: (column) => SelectColumnFilter(column, ['Ja', 'Nee']),
          filter: 'includes',
        },
        {
          Header: 'Quantity',
          accessor: 'quantity',
          className: 'light-green'
        },
        {
          Header: 'Weight',
          accessor: 'weight',
          className: 'light-green',
          Filter: NumberRangeColumnFilter,
          filter: 'between',
        },
        {
          Header: 'Order date',
          accessor: 'orderDate',
          Cell: props => <span>{formatDate(props.value)}</span>,
          className: 'light-green',
          Filter: DateRangeColumnFilter,
          filter: 'dateBetween',
        },
        {
          Header: 'Sales Program',
          accessor: 'salesProgram',
          className: 'light-green',
          Filter: (column) => SelectColumnFilter(column, options.salesProgram),
          filter: 'includes',
        },
        {
          Header: 'Sales Program vertaling',
          accessor: 'salesProgramTranslation',
          className: 'dark-green',
          Filter: (column) => SelectColumnFilter(column, options.salesProgramTranslation),
          filter: 'includes',
        },
        {
          Header: '1 Sales Program',
          accessor: 'krSalesProgram',
          Filter: (column) => SelectColumnFilter(column, options.krSalesProgram),
          className: 'dark-green',
          Cell: (e) => SelectCell(e, options.salesProgram)
        },
        {
          Header: 'Rental',
          accessor: 'rental',
          className: 'dark-green',
          Cell: CheckboxCell,
          Filter: (column) => SelectColumnFilter(column, ['Ja', 'Nee'], false),
          filter: 'includes',
        },
        {
          Header: '1 Rental',
          accessor: 'krRental',
          className: 'dark-green',
          Cell: CheckboxCell,
          Filter: (column) => SelectColumnFilter(column, ['Ja', 'Nee'], false),
          filter: 'includes',
        },
        {
          Header: 'Machine Status date',
          accessor: 'machineStatusDate',
          Cell: props => <span>{formatDate(props.value)}</span>,
          className: 'light-green',
          Filter: DateRangeColumnFilter,
          filter: 'dateBetween',
        },
        {
          Header: 'Machine Status',
          accessor: 'machineStatusCustom',
          className: 'dark-green'
        },
        {
          Header: 'Machine Status vertaling',
          accessor: 'machineStatusTranslation',
          className: 'dark-green'
        },
        {
          Header: 'Datum gereed voor transport (ATP) Date',
          accessor: 'aptDate',
          Cell: props => <span>{formatDate(props.value)}</span>,
          className: 'light-green',
          Filter: DateRangeColumnFilter,
          filter: 'dateBetween',
        },
        {
          Header: 'Presold date',
          accessor: 'preSoldDate',
          Cell: props => <span>{formatDate(props.value)}</span>,
          className: 'light-green',
          Filter: DateRangeColumnFilter,
          filter: 'dateBetween',
        },
        {
          Header: 'Serial Number',
          accessor: 'serialNumber',
          className: 'light-green'
        },
        {
          Header: 'Invoice Number',
          accessor: 'invoiceNumber',
          className: 'light-green'
        },
        {
          Header: 'Invoice date',
          accessor: 'invoiceDate',
          Cell: props => <span>{formatDate(props.value)}</span>,
          className: 'light-green',
          Filter: DateRangeColumnFilter,
          filter: 'dateBetween',
        },
        {
          Header: 'Retail sold date',
          accessor: 'retailSoldDate',
          Cell: props => <span>{formatDate(props.value)}</span>,
          className: 'light-green',
          Filter: DateRangeColumnFilter,
          filter: 'dateBetween',
        },
        {
          Header: 'Delivery date',
          accessor: 'deliveryDate',
          Cell: props => <span>{formatDate(props.value)}</span>,
          className: 'light-green',
          Filter: DateRangeColumnFilter,
          filter: 'dateBetween',
        },
        {
          Header: 'Service start date',
          accessor: 'serviceStartDate',
          Cell: props => <span>{formatDate(props.value)}</span>,
          className: 'light-green',
          Filter: DateRangeColumnFilter,
          filter: 'dateBetween',
        },
        {
          Header: 'Customer Name',
          accessor: 'customerName',
          className: 'light-green'
        },
        {
          Header: 'Bruto',
          accessor: 'bruto',
          Cell: props => <div className='align-right'>{formatNumber(props.value)}</div>,
          className: 'dark-green',
          Filter: NumberRangeColumnFilter,
          filter: 'between',
        },
        {
          Header: 'Gross',
          accessor: 'grossPrice',
          Cell: props => <div className='align-right'>{formatNumber(props.value)}</div>,
          className: 'light-green',
          Filter: NumberRangeColumnFilter,
          filter: 'between',
        },
        {
          Header: 'Discount 1',
          accessor: 'discount1',
          Cell: props => <div className='align-right'>{formatNumber(props.value)}</div>,
          className: 'light-green',
          Filter: NumberRangeColumnFilter,
          filter: 'between',
        },
        {
          Header: 'Discount 2',
          accessor: 'discount2',
          Cell: props => <div className='align-right'>{formatNumber(props.value)}</div>,
          className: 'light-green',
          Filter: NumberRangeColumnFilter,
          filter: 'between',
        },
        {
          Header: 'Discount 3',
          accessor: 'discount3',
          Cell: props => <div className='align-right'>{formatNumber(props.value)}</div>,
          className: 'light-green',
          Filter: NumberRangeColumnFilter,
          filter: 'between',
        },
        {
          Header: 'Freight',
          accessor: 'freightPrice',
          Cell: props => <div className='align-right'>{formatNumber(props.value)}</div>,
          className: 'light-green',
          Filter: NumberRangeColumnFilter,
          filter: 'between',
        },
        {
          Header: 'Insurance',
          accessor: 'insurancePrice',
          Cell: props => <div className='align-right'>{formatNumber(props.value)}</div>,
          className: 'light-green',
          Filter: NumberRangeColumnFilter,
          filter: 'between',
        },
        {
          Header: 'Net',
          accessor: 'netPrice',
          Cell: props => <div className='align-right'>{formatNumber(props.value)}</div>,
          className: 'light-green',
          Filter: NumberRangeColumnFilter,
          filter: 'between',
        },
        {
          Header: 'Verkoopstatus',
          accessor: 'krSalesStatus',
          className: 'yellow',
          Filter: (column) => SelectColumnFilter(column, options.krSalesStatus),
          filter: 'includes',
        },
        {
          Header: 'In transit',
          accessor: 'inTransit',
          Cell: props => <span>{props.value ? 'Ja' : 'Nee'}</span>,
          className: 'light-green',
          Filter: (column) => SelectColumnFilter(column, ['Ja', 'Nee'], false),
          filter: 'includes',
        },
        {
          Header: 'Op voorraad',
          accessor: 'krInStock',
          Cell: props => <span>{props.value ? 'Ja' : 'Nee'}</span>,
          className: 'dark-green',
          Filter: (column) => SelectColumnFilter(column, ['Ja', 'Nee'], false),
          filter: 'includes',
        },
        {
          Header: '14dgn timer',
          accessor: 'kr14DayTimer',
          className: 'dark-green',
          Filter: NumberRangeColumnFilter,
          filter: 'between',
        },
        {
          Header: 'Opmerking verkoopbinnendienst',
          accessor: 'remark',
          className: 'dark-green',
          Cell: TextAreaCell
        },
      ];

      // Remove certain columns if user is not an admin
      if (!authenticationService.isAdmin()) {
        columns = columns.filter(col => col.Header !== 'KMP');
      }

      return columns;
    },
    [options]
  );

  const filterTypes = useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
      dateBetween: dateBetweenFilterFn,
    }),
    []
  )

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter
    }),
    []
  )

  const formatDate = (date) => {
    if (!date) return '';
    return new Date(date).toLocaleDateString('nl-NL');
  };

  const formatNumber = (number) => {
    if (!number) return '';

    return new Intl.NumberFormat('nl-NL').format(number);
  }

  const updateMyData = async (rowIndex, columnId, value) => {
    setSkipPageReset(false);

    let updateData = {};
    updateData[columnId] = value;

    const rowTest = data[rowIndex];

    setLoading(true);

    const response = await apiService.performRequest('PATCH', 'order/' + rowTest.id, updateData);
    fetchData({pageIndex, pageSize, globalFilter, filters, sortBy});

  };

  useEffect(() => {
    setSkipPageReset(true)
  }, [data])

  const tableInstance = useTable({
    columns,
    data,
    defaultColumn,
    filterTypes,
    autoResetPage: !skipPageReset,
    updateMyData,
    initialState: {
      pageIndex: 0,
      pageSize: 50
    },
    manualPagination: true,
    manualGlobalFilter: true,
    manualFilters: true,
    manualSortBy: true,
    pageCount: controlledPageCount
  }, useFilters, useGlobalFilter, useSortBy, useExpanded, usePagination);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setAllFilters,
    state: {pageIndex, pageSize, globalFilter, filters, sortBy},
  } = tableInstance;

  useEffect(() => {
    fetchData({pageIndex, pageSize, globalFilter, filters, sortBy});
  }, [pageIndex, pageSize, globalFilter, filters, sortBy]);

  const addToKmp = async (orderData) => {
    if (window.confirm('Weet je het zeker?')) {
      const response = await apiService.performRequest('GET', 'order/' + orderData.id + '/send_to_kmp');
      fetchData({pageIndex, pageSize, globalFilter, filters, sortBy});
    }
  }

  return (
    <div className="App">
      <div className='table-container'>
        <Table className='table-fixed table-responsive table-striped' {...getTableProps()}>
          <thead>
          {// Loop over the header rows
            headerGroups.map(headerGroup => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {// Loop over the headers in each row
                  headerGroup.headers.map(column => (
                    // Apply the header cell props
                    <th className={column.className} style={{minWidth: column.minWidth}}>

                      <div {...column.getHeaderProps(column.getSortByToggleProps())}>
                        {column.render('Header')}

                        <span>

                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                      </div>

                      <div>{column.canFilter ? column.render('Filter') : null}</div>
                    </th>
                  ))}
              </tr>
            ))}
          <tr>
            <th
              colSpan={visibleColumns.length}
              style={{
                textAlign: 'left',
              }}
            >
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                totalCount={totalCount}
              />
            </th>
          </tr>
          </thead>
          <tbody {...getTableBodyProps()}>
          {// Loop over the table rows
            rows.map(row => {
              // Prepare the row for display
              prepareRow(row);
              // console.log(...row.getRowProps());
              return (
                <Fragment>
                  <tr {...row.getRowProps()}>
                    {// Loop over the rows cells
                      row.cells.map(cell => {
                        // Apply the cell props
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render('Cell')}

                            {/*cell.column.Header === 'Id' && <span className={'collapse-fixed'}>X</span> */}
                          </td>
                        )
                      })}
                  </tr>
                  {row.isExpanded &&
                    <tr>
                      <td colSpan={visibleColumns.length}>
                        <strong>Order codes</strong>
                        <Table style={{width: 500}}>
                          <thead>
                          <tr>
                            <th>Aantal</th>
                            <th>Code</th>
                            <th>Omschrijving</th>
                          </tr>
                          </thead>
                          <tbody>
                          {row.original.orderCodes.map(orderCode =>
                            <tr>
                              <td>{orderCode.quantity}</td>
                              <td>{orderCode.code}</td>
                              <td>{orderCode.description}</td>
                            </tr>
                          )}
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                  }
                </Fragment>
              )
            })}
          </tbody>
        </Table>
      </div>

      <div className="pagination">
        <Button size='sm' style={{marginLeft: 20}} onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </Button>{' '}
        <Button size='sm' onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </Button>{' '}
        <Button size='sm' onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </Button>{' '}
        <Button size='sm' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </Button>{' '}
        <span style={{paddingTop: 3, marginLeft: 10, marginRight: 10}}>
          Pagina{' '}
          <strong>
            {pageIndex + 1} van {pageOptions.length}
          </strong>{' '}
          Totaal <strong>{totalCount} resultaten</strong>
        </span>
        <span>
          | Ga naar pagina:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
            }}
            style={{width: '100px'}}
          />
        </span>{' '}
        <Form.Select
          size={'sm'}
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
          style={{width: 100}}
        >
          {[10, 20, 30, 40, 50, 100].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Toon {pageSize}
            </option>
          ))}
        </Form.Select>

        <Form.Select
          size={'sm'}
          onChange={e => {
            const filterId = e.target.value;
            if (!filterId) {
              setAllFilters([]);
              return;
            }
            const customFilter = customFilters.find(cFilter => cFilter.id === parseInt(filterId));

            setAllFilters(customFilter.filter);
          }}
          style={{width: 200}}
        >
          <option value=''>Opgeslagen filters</option>
          {customFilters.map(customFilter => (
            <option key={customFilter.id} value={customFilter.id}>{customFilter.name}</option>
          ))}
        </Form.Select>

        <Button
          variant='secondary'
          size='sm'
          style={{marginLeft: 20}}
          onClick={() => setAllFilters([])}
        > Reset alle filters
        </Button>

        {authenticationService.isAdmin() &&
          <Button
            variant='warning'
            size='sm'
            style={{marginLeft: 20}}
            onClick={() => setShowFilters(true)}
          >
            Filter beheer
          </Button>
        }
        <Button
          variant="primary"
          size='sm'
          style={{marginLeft: 20}}
          onClick={async () => {
            setDownloadLoading(true);
            const response = await apiService.performRequest('FILE', 'order/excel', {filters});
            FileDownload(response.blob, response.fileName);
            setDownloadLoading(false);
          }}
        >
          Download excel {downloadLoading && <Spinner animation="border" size='sm'/>}
        </Button>

        <Button
          variant="secondary"
          size='sm'
          style={{marginLeft: 20}}
          onClick={() => {
            authenticationService.logout();
          }}
        >
          Uitloggen
        </Button>

        {loading &&
          <span style={{position: 'absolute', right: 50}}>
          <Spinner animation="border" size='sm'/>
        </span>
        }

      </div>

      <Modal
        size="lg"
        show={showFilters}
        onHide={() => setShowFilters(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Filters
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showFilterForm ?
            <Formik
              initialValues={selectedFilter}
              onSubmit={async (values) => {
                let newValues = {...values};
                newValues['filter'] = filters;

                const response = apiService.performRequest('POST', 'filter', newValues);
                await getCustomerFilters();
                setShowFilterForm(false);
              }}
            >
              {props => (
                <Form onSubmit={props.handleSubmit}>
                  <Form.Group className="mb-3">

                    <Form.Group className="mb-3">
                      <Button variant='link' onClick={() => setShowFilterForm(false)} type="submit">Terug</Button>
                    </Form.Group>

                    <Form.Control
                      type="text"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.name}
                      placeholder='Vul de naam van het filter in...'
                      name="name"
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Button type="submit">Toevoegen</Button>
                  </Form.Group>
                </Form>
              )}
            </Formik> :

            <div>
              <p>
                <Button onClick={() => setShowFilterForm(true)}>Sla huidig filter op</Button>
              </p>

              <Table striped bordered hover>
                <thead>
                <tr>
                  <th>#</th>
                  <th>Naam</th>
                  <th>Acties</th>
                </tr>
                </thead>
                <tbody>
                {customFilters.map(customFilter =>
                  <tr key={customFilter.id}>
                    <td>{customFilter.id}</td>
                    <td>{customFilter.name}</td>
                    <td>
                      <Button
                        size='sm'
                        onClick={() => {
                          setAllFilters(customFilter.filter);
                          setShowFilters(false);
                        }}
                      >
                        Stel filter in
                      </Button>

                      &nbsp;

                      <Button
                        size='sm'
                        variant='warning'
                        onClick={async () => {
                          if (window.confirm('Deze actie overschrijft het filter met het huidige ingestelde filter. Weet je het zeker?')) {
                            await apiService.performRequest('PATCH', 'filter/' + customFilter.id, {filter: filters});
                            await getCustomerFilters();
                          }
                        }}
                      >
                        Overschrijf filter
                      </Button>

                      &nbsp;

                      <Button
                        size='sm'
                        variant='danger'
                        onClick={async () => {
                          if (window.confirm('Weet je zeker dat je dit filter wilt verwijderen?')) {
                            await apiService.performRequest('DELETE', 'filter/' + customFilter.id);
                            await getCustomerFilters();
                          }
                        }}
                      >
                        Verwijder filter
                      </Button>
                    </td>
                  </tr>
                )}
                </tbody>
              </Table>
            </div>
          }
        </Modal.Body>
      </Modal>
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, {keys: [row => row.values[id]]})
}

fuzzyTextFilterFn.autoRemove = val => !val

function dateBetweenFilterFn(rows, id, filterValues) {
  let sd = new Date(filterValues[0]);
  let ed = new Date(filterValues[1]);
  return rows.filter(r => {
    var time = new Date(r.values[id]);
    console.log(time, ed, sd)
    if (filterValues.length === 0) return rows;
    return (time >= sd && time <= ed);
  });
}

dateBetweenFilterFn.autoRemove = val => !val;


export default OrderOverview;
