import React from "react";
import {useAsyncDebounce} from "react-table";
import authenticationService from "../Services/authenticationService";

const CheckboxCell = ({
                        value: initialValue,
                        row: { index, original },
                        column: { id },
                        updateMyData, // This is a custom function that we supplied to our table instance
                      }) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(original[id])

  const onChange = e => {
    setValue(e.target.checked)

    updateMyData(index, id, e.target.checked)
  }

  // We'll only update the external data when the input is blurred
  const onBlur = () => {

  }

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue)
  }, [original[id]]);

  if (authenticationService.isAdmin()) {
    return <input type='checkbox' checked={value} onChange={onChange} />
  } else {
    return value ? 'Ja': 'Nee'
  }

}

export default CheckboxCell;