import React from 'react';
import {BrowserRouter, Route, Routes, Navigate} from "react-router-dom";
import Login from "./Login";
import OrderOverview from "./OrderOverview";
import {StateContext} from "./Contexts/StateContext";
import authenticationService from "./Services/authenticationService";

const App = () => {
  const [state] = React.useContext(StateContext);

  return (
    <BrowserRouter>
      {state.isLoggedIn || authenticationService.isLoggedIn() ?
        <Routes>
          <Route path='/' element={<OrderOverview />} />
          <Route path="*" element={<Navigate to="/" replace/>}/>
        </Routes> :
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path="*" element={<Navigate to="/login" replace/>}/>
        </Routes>
      }
    </BrowserRouter>
  )
}

export default App;