import React, {useEffect, useState} from "react";
import {useAsyncDebounce} from "react-table";
import {Form} from "react-bootstrap";
import { MultiSelect } from 'primereact/multiselect';
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css

const SelectColumnFilter = ({column: { filterValue, setFilter, preFilteredRows, id }}, options, multiselect = true) => {

  useEffect(() => {
    setValue(filterValue);
  }, [filterValue])

  const [value, setValue] = useState('');

  if (multiselect) {
    // Render a multi-select box
    return (
      <MultiSelect
        value={filterValue ? filterValue : []}
        options={options.map(option => ({label: option, value: option}))}
        onChange={(e) => {
          //setValues(e.value)
          setFilter(e.value || []);
        }}
        maxSelectedLabels={2}
      />
    )
  } else {
    return (
      <select
        style={{padding: '.375rem 2.25rem .375rem .75rem'}}
        value={value || ''}
        onChange={e => {
          setFilter(e.target.value || undefined);
          setValue(e.target.value || undefined);
        }}
      >
        <option value="">&nbsp;</option>
        {options.map((option, i) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    )
  }
}

export default SelectColumnFilter;