export const reducer = (state, action) => {
  switch (action.type) {
    case "isLoggedIn":
      return {
        ...state,
        isLoggedIn: action.isLoggedIn
      }
    default:
      return state
  }
}

export const initialState = {
  isLoggedIn: false
}