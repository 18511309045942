// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
import React, {forwardRef, useState} from "react";
import {useAsyncDebounce} from "react-table";
import {Form} from "react-bootstrap";
import DatePicker, {registerLocale} from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import nl from 'date-fns/locale/nl';
registerLocale('nl', nl)


const NumberRangeColumnFilter = ({column: { filterValue = [], preFilteredRows, setFilter, id }}) => {

  const [minValue, setMinValue] = useState(filterValue[0]);
  const [maxValue, setMaxValue] = useState(filterValue[1]);

  const onChangeMin = useAsyncDebounce((value) => {
    setFilter((old = []) => [value ? value : undefined, old[1]])
  }, 500);

  const onChangeMax = useAsyncDebounce((value) => {
    setFilter((old = []) => [old[0], value ? value : undefined])
  }, 500);

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <DatePicker
        className='form-control date-filter'
        selected={minValue}
        onChange={date => {
          setMinValue(date);
          onChangeMin(date);
        }}
        locale='nl'
        dateFormat="dd-MM-yyyy"
        isClearable
      />
      {' '} tot {' '}
      <DatePicker
        className='form-control date-filter'
        selected={maxValue}
        onChange={date => {
          setMaxValue(date);
          onChangeMax(date);
        }}
        locale='nl'
        dateFormat="dd-MM-yyyy"
        isClearable
      />
    </div>
  )
}

export default NumberRangeColumnFilter;