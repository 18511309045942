import React, {useEffect, useState} from "react";
import { useAsyncDebounce } from "react-table";
import {Form} from "react-bootstrap";


const DefaultColumnFilter = ({column}) => {
  const { filterValue, setFilter, preFilteredRows } = column;
  const count = preFilteredRows.length
  const [value, setValue] = useState(filterValue);
  const onChange = useAsyncDebounce((value) => {
    setFilter(value || undefined);
  }, 300);

  useEffect(() => {
    setValue(filterValue);
  }, [filterValue])

  return (
    <Form.Control
      value={value || ""}
      onChange={e => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      placeholder={`Zoekwoord...`}
    />
  )
}

export default DefaultColumnFilter