import React from "react";
import {Form} from 'react-bootstrap';
import authenticationService from "../Services/authenticationService";

const SelectCell = ({
                        value: initialValue,
                        row: { index },
                        column: { id },
                        updateMyData, // This is a custom function that we supplied to our table instance
                      }, options = []) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue)

  const onChange = e => {
    setValue(e.target.value)
    updateMyData(index, id, e.target.value)
  }

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue]);

  if (authenticationService.isAdmin()) {
    return <Form.Select value={value} onChange={onChange}>
      {options.map(option => <option value={option}>{option}</option>)}
    </Form.Select>
  } else {
    return value;
  }
}

export default SelectCell;