import React from "react";
import authenticationService from "../Services/authenticationService";

const TextareaCell = ({
                        value: initialValue,
                        row: { index },
                        column: { id },
                        updateMyData, // This is a custom function that we supplied to our table instance
                      }) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue)

  const onChange = e => {
    setValue(e.target.value)
  }

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value)
  }

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  if (authenticationService.isAdmin()) {
    return <textarea rows={1} onChange={onChange} onBlur={onBlur} value={value}/>
  } else {
    return value;
  }
}

export default TextareaCell;